<template>
  <b-modal id="forms-bo-share-modal" centered hide-footer :title="$t('backoffice.communication.forms.share')">
    <h4 class="text-center my-1">
      {{ $t('organizations.qr.share-title') }}
    </h4>
    <img :src="qrLink" style="width: 250px" class="d-block mx-auto small-card">
    <div class="text-center mt-2 mb-1">
      <b-button class="font-weight-bold" :variant="'primary'" @click="handleClipboardCopied()">
        <span class="d-flex pb-25"><feather-icon icon="CopyIcon" size="18" class="d-block mx-auto mr-1" /><span
            class="pl-50 pt-25">{{
              $t('share.clipboard-title')
            }}</span></span>
      </b-button>
    </div>
    <b-alert :show="isCopied" variant="primary" class="text-center">
      {{ $t('share.copied-message') }}
    </b-alert>
  </b-modal>
</template>

<script>
import QRCode from 'qrcode';

export default {

  name: 'ShareQRModal',
  props: {
    params: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCopied: false,
      qrLink: '',
    };
  },
  computed: {
    community() {
      return this.$store.getters.currentCollective;
    },
    qrUrl() {
      const url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        this.community.subdomain || 'app',
      ).replace('{slug}', this.community.slug);
      return `${url}/${this.params}`;
    },

  },
  async created() {
    this.qrLink = await this.link();
  },
  methods: {
    handleClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.qrUrl);
    },
    async link() {
      try {
        const qrLinkUrl = await QRCode.toDataURL(this.qrUrl);
        return qrLinkUrl;
      } catch (err) {
        console.error(err)
      }
    },
  },
};

</script>

<style></style>
